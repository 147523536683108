type Cleanable = { [key: string]: any };

export class Schema {
  static cleanse(data: Cleanable): Cleanable {
    return Object.entries(data).reduce((acc, [key, value]) => {
      if (value !== null && value !== '') {
        acc[key] =
          typeof value === 'object'
            ? Array.isArray(value)
              ? value.map((v: Cleanable) =>
                  typeof v === 'object' ? this.cleanse(v) : v,
                )
              : this.cleanse(value)
            : value;
      }

      return acc;
    }, {} as Cleanable);
  }
}
