import { Schema } from 'store/schemas/Schema';

export class GraphSchema extends Schema {
  items;

  constructor({ items }: { items: Schema[] }) {
    super();

    this.items = items;
  }

  toJSON() {
    return Schema.cleanse({
      '@context': 'http://schema.org',
      '@graph': this.items,
    });
  }
}
