import {
  CategoryTypes,
  rdbV2LatestList,
  rdbV2Nil100List,
  SiteTemplate,
  v1CategoriesFeaturedHeadlinesDetail,
  v1CategoriesHeadlinesPaginatedDetail,
  v1SitesForumsLatestPostsDetail,
  v1SitesHeadlinesForCategoryTypeDetail,
  type V1SitesHeadlinesForCategoryTypeDetailParams,
  v1SitesPremiumHeadlinesList,
  v2OrganizationsScheduleDetail,
  type V2OrganizationsScheduleDetailParams,
} from '@on3/api';
import { ISiteContext } from '@on3/ui-lib/contexts/SiteProvider';
import withAuthentication from '@on3/ui-lib/src/hocs/withAuthentication';
import withValidUrl from '@on3/ui-lib/src/hocs/withValidUrl';
import { getFulfilledValue, proxyApi } from '@on3/ui-lib/utils/api';
import { FeedHome, IFeedHomeProps } from 'components/Home/FeedHome';
import {
  ITraditionalHomeProps,
  TraditionalHome,
} from 'components/Home/TraditionalHome';
import { PageWrapper } from 'components/Layouts/PageWrapper';
import type { GetServerSideProps } from 'next';
import { GraphSchema } from 'store/schemas/Graph';
import { OrganizationSchema } from 'store/schemas/Organization';
import { ScheduleSchema } from 'store/schemas/Schedule';
import { createCanonicalUrl } from 'utils/meta';

interface ITeamResponse extends IFeedHomeProps, ITraditionalHomeProps {
  siteData?: ISiteContext;
}

export const TeamPage = ({ siteData, ...props }: ITeamResponse) => {
  const { currentSite } = siteData || {};
  const { organization, siteName, template, url: siteUrl } = currentSite || {};

  const title = `${siteName}: ${organization?.name} ${organization?.mascot} Football & Basketball`;
  const description = `The latest ${organization?.name} ${organization?.mascot} news, recruiting, transfers, and NIL information at ${siteName}, part of on3.com`;

  const canonical = createCanonicalUrl(siteUrl as string);

  const schemaItems = [
    new OrganizationSchema().toJSON(),
    new OrganizationSchema({
      name: organization?.name || '',
      url: canonical,
      logo: {
        url: organization?.defaultAsset?.url || '',
        width: organization?.defaultAssetRes?.width || 0,
        height: organization?.defaultAssetRes?.height || 0,
      },
    }).toJSON(),
  ];

  if (props.teamSchedule) {
    schemaItems.push(
      new ScheduleSchema({
        name: organization?.name || '',
        url: canonical,
        sport: props.teamSchedule.relatedModel?.sport?.name || '',
        event: props.teamSchedule.list || [],
      }).toJSON(),
    );
  }

  const schema = new GraphSchema({
    items: schemaItems,
  }).toJSON();

  if (template === 'Feed') {
    return (
      <PageWrapper
        {...props}
        canonical={canonical}
        description={description}
        schema={schema}
        siteData={siteData}
        title={title}
      >
        <FeedHome {...props} />
      </PageWrapper>
    );
  }

  return (
    <PageWrapper
      {...props}
      canonical={canonical}
      description={description}
      schema={schema}
      siteData={siteData}
      title={title}
    >
      <TraditionalHome {...props} />
    </PageWrapper>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const siteData = await withValidUrl.isValid(ctx);
  const userData = await withAuthentication.isAuth(ctx);
  const ksrPlusSiteKey = 395;

  if (!siteData) {
    return {
      notFound: true,
    };
  }

  const { currentSite } = siteData;

  if (!currentSite) {
    return {
      notFound: true,
    };
  }

  try {
    const {
      siteCategories,
      key: siteKey,
      siblingSiteKey,
      organizationKey,
    } = currentSite;

    const mainCategory =
      siteCategories?.find(
        (category) => category.categoryType === CategoryTypes.Home,
      )?.categoryKey || 0;

    let data = {};

    const timeOut = {
      timeout: 2000,
    };
    const mainCategoryParams = {
      category: mainCategory,
    };

    const currentSiteParams = {
      siteKey,
    };
    const siblingSiteParams = {
      siteKey: siblingSiteKey || 0,
    };

    const siteSportParams: V1SitesHeadlinesForCategoryTypeDetailParams = {
      ...currentSiteParams,
      type: CategoryTypes.Sport,
    };
    const siteRecParams: V1SitesHeadlinesForCategoryTypeDetailParams = {
      ...currentSiteParams,
      type: CategoryTypes.Recruiting,
    };
    const highSchoolParams: V1SitesHeadlinesForCategoryTypeDetailParams = {
      ...currentSiteParams,
      type: CategoryTypes.HighSchool,
    };
    const siblingSportParams: V1SitesHeadlinesForCategoryTypeDetailParams = {
      ...siblingSiteParams,
      type: CategoryTypes.Sport,
    };
    const siblingRecParams: V1SitesHeadlinesForCategoryTypeDetailParams = {
      ...siblingSiteParams,
      type: CategoryTypes.Recruiting,
    };
    const orgParams: V2OrganizationsScheduleDetailParams = {
      orgKey: organizationKey || 0,
    };
    const scheduleParams: V2OrganizationsScheduleDetailParams = {
      ...orgParams,
      // Todo, make sport configureable overall and by site
      sportKey: 1,
    };

    if (currentSite?.template === SiteTemplate.Feed) {
      const [
        featuredArticles,
        mainFeed,
        channelSports,
        channelRecruiting,
        premiumArticles,
        messageBoard,
        teamSchedule,
      ] = await Promise.allSettled([
        v1CategoriesFeaturedHeadlinesDetail(proxyApi, mainCategory, timeOut),
        v1CategoriesHeadlinesPaginatedDetail(proxyApi, mainCategoryParams),
        v1SitesHeadlinesForCategoryTypeDetail(proxyApi, siblingSportParams),
        v1SitesHeadlinesForCategoryTypeDetail(proxyApi, siblingRecParams),
        v1SitesPremiumHeadlinesList(proxyApi, siblingSiteParams),
        v1SitesForumsLatestPostsDetail(proxyApi, ksrPlusSiteKey),
        v2OrganizationsScheduleDetail(proxyApi, scheduleParams),
      ]);

      data = {
        siteData,
        userData: userData || {},
        featuredArticles: getFulfilledValue(featuredArticles),
        mainFeed: getFulfilledValue(mainFeed),
        channelSports: getFulfilledValue(channelSports),
        channelRecruiting: getFulfilledValue(channelRecruiting),
        premiumArticles: getFulfilledValue(premiumArticles),
        messageBoard: getFulfilledValue(messageBoard),
        teamSchedule: getFulfilledValue(teamSchedule),
      };
    } else {
      //Traditional
      const traditionalPromises = [
        v1CategoriesFeaturedHeadlinesDetail(proxyApi, mainCategory, timeOut),
        v1SitesHeadlinesForCategoryTypeDetail(proxyApi, siteSportParams),
        v1SitesHeadlinesForCategoryTypeDetail(proxyApi, siteRecParams),
        v1SitesHeadlinesForCategoryTypeDetail(proxyApi, siblingSportParams),
        v1SitesHeadlinesForCategoryTypeDetail(proxyApi, siblingRecParams),
        v1SitesForumsLatestPostsDetail(proxyApi, siteKey),
        rdbV2LatestList(proxyApi, orgParams),
        rdbV2Nil100List(proxyApi, orgParams),
        v1SitesPremiumHeadlinesList(proxyApi, currentSiteParams),
        v2OrganizationsScheduleDetail(proxyApi, scheduleParams),
      ];

      if ([25, 74].includes(currentSite?.key)) {
        traditionalPromises.push(
          v1SitesHeadlinesForCategoryTypeDetail(proxyApi, highSchoolParams),
        );
      }

      const [
        featuredArticles,
        siteSports,
        siteRecruiting,
        channelSports,
        channelRecruiting,
        messageBoard,
        latestCommits,
        teamNil100,
        premiumArticles,
        teamSchedule,
        highSchoolArticles,
      ] = await Promise.allSettled(traditionalPromises);

      data = {
        siteData,
        userData: userData || {},
        featuredArticles: getFulfilledValue(featuredArticles),
        siteSports: getFulfilledValue(siteSports),
        siteRecruiting: getFulfilledValue(siteRecruiting),
        channelSports: getFulfilledValue(channelSports),
        channelRecruiting: getFulfilledValue(channelRecruiting),
        messageBoard: getFulfilledValue(messageBoard),
        latestCommits: getFulfilledValue(latestCommits),
        teamNil100: getFulfilledValue(teamNil100),
        premiumArticles: getFulfilledValue(premiumArticles),
        teamSchedule: getFulfilledValue(teamSchedule),
        highSchoolArticles: getFulfilledValue(highSchoolArticles),
      };
    }

    return { props: { ...data, pageType: 'home' } };
  } catch (e) {
    return { props: {} };
  }
};

export default withValidUrl(TeamPage);
