import { Schema } from 'store/schemas/Schema';

export interface IOrganizationSchema {
  name: string;
  url?: string | null;
  logo?: {
    url: string;
    width?: number;
    height?: number;
  } | null;
}

export class OrganizationSchema extends Schema {
  name;
  url;
  logo;

  constructor(data?: IOrganizationSchema) {
    super();

    if (data) {
      this.name = data.name;
      this.url = data.url;
      this.logo = data.logo;
    } else {
      this.name = 'On3';
      this.url = 'https://www.on3.com/';
      this.logo = {
        url: 'https://on3static.com/uploads/dev/assets/cms/2023/01/23142710/on3-share.jpg',
        width: 1200,
        height: 627,
      };
    }
  }

  toJSON() {
    return Schema.cleanse({
      '@context': 'http://schema.org',
      '@type': 'Organization',
      name: this.name,
      url: this.url,
      logo: this.logo
        ? {
            '@type': 'ImageObject',
            ...this.logo,
          }
        : null,
    });
  }
}
