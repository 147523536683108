import { webUrl } from '@on3/ui-lib/utils/api';

export const capitalizeParts = (str: string): string => {
  return str.replace(/\b\w/g, (char, index, input) => {
    if (index > 0 && input[index - 1] === "'") {
      return char;
    }

    return char.toUpperCase();
  });
};

export const createTitle = (...parts: any[]): string => {
  return capitalizeParts(parts.filter(Boolean).join(' '));
};

export const createDescription = (template: string, ...args: any[]) => {
  return template
    .replace(/{(\d+)}/g, (match, index) => {
      return typeof args[index] !== 'undefined' ? args[index] : match;
    })
    .replace(/\s{2,}/g, ' ');
};

export const createCanonicalUrl = (
  path: string,
  query?: Record<string, string>,
) => {
  // Ensure path ends with a trailing slash
  if (!path.endsWith('/')) {
    path += '/';
  }

  const url = new URL(path, webUrl);

  if (query) {
    Object.entries(query).forEach(([key, value]) => {
      if (value) {
        url.searchParams.set(key, value);
      }
    });
  }

  return url.toString();
};
