import { GameStatuses, ScoreboardResponse } from '@on3/api';
import { formatISO, fromUnixTime } from 'date-fns';
import { Schema } from 'store/schemas/Schema';

interface IScheduleSchema {
  name: string;
  url: string;
  sport?: string;
  event?: ScoreboardResponse[];
}

export class ScheduleSchema extends Schema {
  name;
  url;
  sport;
  event;

  constructor({ name, url, sport, event }: IScheduleSchema) {
    super();
    this.name = name;
    this.url = url;
    this.sport = sport;
    this.event = event;
  }

  getStartDate(date?: number | null) {
    if (!date) return;

    return formatISO(fromUnixTime(date));
  }

  getResult(game: ScoreboardResponse) {
    if (
      game.statusEnum === GameStatuses.Completed &&
      game.homeTeamScore &&
      game.awayTeamScore
    ) {
      return game.homeTeamScore > game.awayTeamScore
        ? `${game.homeTeamOrganization?.name} won ${game.homeTeamScore}-${game.awayTeamScore}`
        : `${game.awayTeamOrganization?.name} won ${game.awayTeamScore}-${game.homeTeamScore}`;
    }

    return 'TBD';
  }

  toJSON() {
    return Schema.cleanse({
      '@context': 'http://schema.org',
      '@type': 'SportsTeam',
      name: this.name,
      url: this.url,
      sport: this.sport,
      event: this.event?.map((game: ScoreboardResponse) => {
        const current = game.currentOrgIsHome
          ? game.homeTeamOrganization
          : game.awayTeamOrganization;

        const opponent = game.currentOrgIsHome
          ? game.awayTeamOrganization
          : game.homeTeamOrganization;

        const isHome = game.currentOrgIsHome;

        return {
          '@type': 'SportsEvent',
          name: `${current?.name} ${isHome ? 'vs' : '@'} ${opponent?.name}`,
          startDate: this.getStartDate(game.startDateUtc),
          location: {
            '@type': 'Place',
            name: game.location ?? game.city ?? 'TBD',
          },
          competitor: [
            {
              '@type': 'SportsTeam',
              name: current?.name,
            },
            {
              '@type': 'SportsTeam',
              name: opponent?.name,
            },
          ],
          homeTeam: {
            '@type': 'SportsTeam',
            name: game.homeTeamOrganization?.name,
          },
          awayTeam: {
            '@type': 'SportsTeam',
            name: game.awayTeamOrganization?.name,
          },
          description: this.getResult(game),
        };
      }),
    });
  }
}
